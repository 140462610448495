export default[
    '#CB7DF6',
    '#FF4A52',
    '#FFA300',
    '#00C1D5',
    '#FF5B88',
    '#D28558',
    '#40C914',
    '#06D6AF',
    '#50A9F9',
    '#8181FF',
    '#FFA300',
    '#8181FF',
    '#00C1D5',
    '#CB7DF6',
    '#FF4A52',
    '#FFA300',
    '#00C1D5',
    '#FF5B88',
    '#D28558',
    '#40C914',
    '#06D6AF',
    '#50A9F9',
    '#8181FF',
    '#FFA300',
    '#8181FF',
    '#00C1D5'
]