<template>
    <div>
        <div class="class-body">
            <div :class="{
                'class-card':true,
                's-card':selectClass == item.key_name,
            }" :style="{
                'background':colorList[i]
            }" v-for="(item,i) in classList" :key="item.category" @click="getClassName(item.key_name)">
                <img :src="item.url" alt="">
                {{item.key_name}}
            </div>
        </div>
        <adContent></adContent>
        <div class="game-list">
            <div class="game-card pointer" v-for="item in gameList" :key="item.id" @click="goDetails(item.id)">
                <div class="card-img">
                    <img :src="item.logo" alt="">
                    <div class="play-btn">PLAY</div>
                </div>
                <div class="card-title">{{item.title}}</div>
            </div>
            <span style="width:172px" v-for="item in 5" :key="item"></span>
        </div>
        <div class="game-blurb noselect">
            {{ blurbUrl }} is a trusted source for endless entertainment, offering a vast selection of free online games designed to keep players hooked. With over 3,500 titles, the site covers a wide array of genres, from casual puzzle games and time-wasting classics to immersive role-playing adventures and competitive multiplayer matches. Addicting Games' intuitive interface and user-friendly features make it easy for players to find and enjoy their next favorite game. The platform's active community fosters a sense of camaraderie, with users sharing tips, high scores, and game reviews. Regular updates ensure that the game library remains fresh and exciting, catering to both returning fans and new visitors alike.
        </div>
    </div>
</template>

<script>
import colorList from '@/utils/color'
import navMenu from '@/utils/navMenu'
import { searchGame, getClassList, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: [],
                classList: [],
                selectClass: 'New',
                colorList,
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            let name = this.$route.params.searchtext;
            if(name){
                this.searchGame(name);
            }else{
                this.getList();
            }
            this.getClassList();
        },
        methods: {
            async searchGame(name){
                let res = await searchGame({q:name});
                this.gameList = res;
            },
            async getList(){
                let res = await getGame();
                this.gameList = res;
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(el => {
                    navMenu.forEach(item=>{
                        if(item.name==el.key_name){
                            el.url = item.wurl
                        }
                    })
                });
                this.classList = classlist;
            },
            async getClassName(category){
                this.$router.push({
                    path:'/selectGame',
                    query:{
                        id:'more',
                        key:category
                    }
                })
                // this.selectClass = category;
                // let res = await getGameByKey({category});
                // this.gameList = res;
            },
            goDetails(id){
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.pointer{
    cursor: pointer;
}
.class-body{
    display: flex;
    margin-top: 54px;
    background: #FFF;
    border-radius: 18px;
    padding: 10px;
    box-sizing: border-box;
    overflow: auto;
    white-space: nowrap;
    .class-card{
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px 19px;
        border-radius: 9px;
        box-sizing: border-box;
        text-align: center;
        margin: 5px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 12px;
        color: #FFF;
        font-style: normal;
        text-transform: none;
        img{
            width: 23px;
            height: 23px;
        }
    }
    .s-card{
        border: 1px solid #000;
    }
}
.game-list{
    background: #FFFFFF;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 18px;
    margin-top: 42px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .game-card{
        width: 172px;
        background: #FFF;
        border-radius: 9px;
        padding: 10px 0 13px;
        box-sizing: border-box;
        margin-bottom: 10px;
        border: 1px solid #E8E8E8;
        .card-img{
            margin: 0 auto;
            width: 144px;
            height: 144px;
            border: 9px;
            border-radius: 16px;
            overflow: hidden;
            img{
                width: 144px;
                height: 144px;
            }
        }
        .card-title{
            padding: 0 10px;
            box-sizing: border-box;
            height: 18px;
            margin-top: 13px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 14px;
            color: #000;
            line-height: 19px;
            font-style: normal;
            text-transform: none;

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>